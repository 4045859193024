export default {
  baseStyle: {
    borderRadius: "full",
    paddingY: "space-8",
    paddingX: "15px",
    fontWeight: "medium",
    lineHeight: "none",
    textTransform: "none"
  },

  sizes: {},

  variants: {
    "orange" : {
      bg: "brown-0",
      color: "white",
      fontWeight: "bold"
    },
    "blue" : {
      bg: "blue-0",
      color: "white",
      fontWeight: "bold"
    },
    "red": {
      bg: "red-0",
      color: "white",
      fontWeight: "bold"
    },
    'nav-blue': {
      bg: "blue-2",
      borderRadius: "4px",
    },
    'nav-grey': {
      bg: "grey-4",
      borderRadius: "4px",
    },
  },

  defaultProps: {
    variant: "orange",
  },
};
