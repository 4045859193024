export default {
  baseStyle: {
    _hover: {
      textDecoration: "none",
    },
  },

  sizes: {},

  variants: {
    blue: {
      color: "text-primary",
    },
    //underline
    "link-secondary": {
      lineHeight: 1.1,
      borderRadius: "none",
      bg: "transparent",
      color: "text-primary",
      paddingX: 0,
      display: "inline",
    },
    "link-blog": {
      fontWeight: "normal",
      color: "bg-primary-active",
    },
  },

  defaultProps: {},
};
