const typography = {
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },

  lineHeights: {
    normal: "normal",
    none: 1,
    shortest: 1.15,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.68, // original : 1.625
    taller: "2",
    "3": ".75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
  },

  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },

  fonts: {
    heading: `'Value Serif Pro', Georgia, serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `'Mabry Pro', -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
  },

  fontSizes: {
    "font-8": "8px",
    "font-9": "9px",
    "font-10": "10px",
    "font-12": "12px",
    "font-13": "13px",
    "font-14": "14px",
    "font-15": "15px",
    "font-16": "16px",
    "font-17": "17px",
    "font-18": "18px",
    "font-20": "20px",
    "font-22": "22px",
    "font-24": "24px",
    "font-25": "25px",
    "font-28": "28px",
    "font-30": "30px",
    "font-34": "34px",
    "font-35": "35px",
    "font-40": "40px",
    "font-42": "42px",
    "font-46": "46px",
    "font-50": "50px",
    "font-52": "52px",
    "font-58": "58px"
  },
};

export default typography;
